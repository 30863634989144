export const NAV_LINK = [
  {
    label: "Trade",
    link: "/",
    disable: true,
  },
  {
    label: "Bridge",
    link: "/",
    disable: true,
  },
  {
    label: "Launch",
    link: "/",
  },
];
