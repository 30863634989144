import { IconButton, Tab, Tabs, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { HeaderMenu, MobileMenu } from "components/header/headerMenu/HeaderMenu";
import { AppLogo } from "components/appLogo";
import { SearchBar } from "components/header/headerSearchBar";
import {
  SearchBarContent,
  HeaderContent,
  HeaderExampleText,
  HeaderOptionalContent,
  HeaderWrapper,
  HeaderExampleLink,
  HeaderExampleTextWrapper,
  NavLinksContainer,
} from "./styled";
import { EXAMPLE_ADDRESS } from "consts";
import { NavLink, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { NAV_LINK } from "data/navList";

export const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [example, setExample] = useState<string | undefined>(undefined);
  const matches = useMediaQuery("(min-width:900px)");

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const topRef = useRef<null | HTMLDivElement>(null);

  const tabs = ["mint", "edit", "burn"];
  const defaultTab = searchParams.get("tab") || "mint";
  const [value, setValue] = useState(tabs.indexOf(defaultTab));

  const tab = searchParams.get("tab") || "mint";

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (tabs[newValue] === "mint") {
      navigate("/");
      setValue(newValue);
    } else {
      setValue(newValue);
      navigate(`?tab=${tabs[newValue]}`);
    }
  };

  const resetExample = useCallback(() => {
    setExample(undefined);
  }, []);

  useEffect(() => {
    topRef.current?.scrollIntoView();
  }, [location]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabStyle = {
    fontSize: "19px",
    color: "white",
    borderBottom: "2px solid transparent",
  };

  const activeTabStyle = {
    ...tabStyle,
    borderBottom: "2px solid #65bfed",
  };

  return (
    <>
      <HeaderWrapper position="static" ref={topRef}>
        <HeaderContent>
          <HeaderOptionalContent>
            {!matches && (
              <IconButton onClick={() => setMobileMenu(true)}>
                <MenuRoundedIcon style={{ width: 40, height: 40, color: "#50A7EA" }} />
              </IconButton>
            )}

            {matches && <AppLogo />}
            {matches && (
              <NavLinksContainer>
                {NAV_LINK.map((navItem, key) => (
                  <NavLink
                    to={navItem.link}
                    style={{
                      color: "white",
                      fontWeight: 400,
                      textDecoration: "none",
                      margin: "0px 30px",
                      outline: "none",
                      pointerEvents: navItem.disable ? "none" : "auto",
                    }}>
                    {navItem.label}
                  </NavLink>
                ))}
              </NavLinksContainer>
            )}
            {matches && <HeaderMenu />}
          </HeaderOptionalContent>

          <MobileMenu showMenu={mobileMenu && !matches} closeMenu={() => setMobileMenu(false)} />
        </HeaderContent>
      </HeaderWrapper>
      <Box
        sx={{
          marginTop: "80px",
          width: matches ? "30%" : "70%",
          marginX: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          background: "var(--fe-blue-shadow)",
          border: "0.5px solid rgba(114, 138, 150, 0.16)",
          borderRadius: 5,
          padding: "10px 10px",
          zIndex: 999,
        }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Mint" {...a11yProps(0)} style={value === 0 ? activeTabStyle : tabStyle} />
          <Tab label="Edit" {...a11yProps(1)} style={value === 1 ? activeTabStyle : tabStyle} />
          <Tab label="Burn" {...a11yProps(2)} style={value === 2 ? activeTabStyle : tabStyle} />
        </Tabs>
      </Box>
      {tab !== "mint" && (
        <SearchBarContent>
          <Box
            sx={{
              width: matches ? "50%" : "100%",
              marginTop: "150px",
              marginX: "auto",
            }}>
            <SearchBar
              example={example}
              resetExample={resetExample}
              closeMenu={() => setMobileMenu(false)}
            />
            <HeaderExampleTextWrapper>
              <HeaderExampleText>
                Enter an existing Jetton contract address.
                <HeaderExampleLink variant="body2" onClick={() => setExample(EXAMPLE_ADDRESS)}>
                  {" "}
                  example
                </HeaderExampleLink>
              </HeaderExampleText>
            </HeaderExampleTextWrapper>
          </Box>
        </SearchBarContent>
      )}
      <Outlet />
    </>
  );
};
