import { Drawer, styled } from "@mui/material";
import { Box } from "@mui/system";
import githubIcon from "assets/icons/github-logo.svg";
import { NAV_LINK } from "data/navList";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AppLogo } from "components/appLogo";
import {
  CloseMenuButton,
  DrawerContent,
  StyledGithubIcon,
  AppMenu,
  HeaderTypography,
} from "./styled";
import { TonConnectButton } from "@tonconnect/ui-react";
import { Link, NavLink } from "react-router-dom";

interface MenuProps {
  closeMenu?: () => void;
  showMenu?: boolean;
}

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "var(--fe-blue-shadow)",
  },
}));

const MobileMenu: React.FC<MenuProps> = ({ closeMenu, showMenu }) => {
  return (
    <CustomDrawer anchor="left" open={showMenu} onClose={closeMenu}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px 0px 0px 30px",
        }}>
        <AppLogo />
        <CloseMenuButton onClick={closeMenu}>
          <CloseRoundedIcon style={{ width: 30, height: 30 }} />
        </CloseMenuButton>
      </div>
      <DrawerContent>
        {NAV_LINK.map((navItem, key) => (
          <NavLink
            to={navItem.link}
            key={key}
            style={{
              color: "white",
              fontWeight: 400,
              textDecoration: "none",
              margin: "10px 30px",
              outline: "none",
            }}>
            {navItem.label}
          </NavLink>
        ))}
        <HeaderMenu showMenu={showMenu} closeMenu={closeMenu} />
      </DrawerContent>
    </CustomDrawer>
  );
};

const HeaderMenu: React.FC<MenuProps> = (props) => {
  return (
    <AppMenu>
      <div onClick={props.closeMenu}>
        <StyledTonConnectButton />
      </div>
    </AppMenu>
  );
};

const StyledTonConnectButton = styled(TonConnectButton)(({ theme }) => ({
  button: {
    padding: "10px 20px",

    background: "var(--fe-light-blue)",
    "*": { color: "white" },
    svg: {
      "*": {
        stroke: "white",
      },
    },
  },
}));

export { HeaderMenu, MobileMenu };
