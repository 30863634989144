import { Typography } from "@mui/material";
import { CredentialsWrapper, FooterTextBoxLeft, FooterWrapper, Separator } from "./styled";
import { Outlet } from "react-router-dom";

export const Footer = () => {
  return (
    <FooterWrapper>
      <Separator />
      <CredentialsWrapper>
        <FooterTextBoxLeft>
          <Typography variant="body2">© 2024 TONR</Typography>
        </FooterTextBoxLeft>
      </CredentialsWrapper>
      <Outlet />
    </FooterWrapper>
  );
};
